'use client';
import { useInView } from 'react-intersection-observer';
import { ReactNode } from 'react';

type slideInRightType = {
  slide_type: string;
  class_name?: string;
  children: ReactNode;
};

const SlideIn = (props: slideInRightType) => {
  const { children } = props;
  const slide_type = props.slide_type;
  const class_name = props.class_name;

  //
  const { ref, inView } = useInView({
    rootMargin: '-10px',
    triggerOnce: true,
  });

  return (
    <div ref={ref} className={`${inView ? slide_type + ' ' + class_name : class_name}`}>
      {children}
    </div>
  );
};
export default SlideIn;
