import React from 'react';
import PaginationButton from '@/app/components/paginationButton';

interface PaginationProps {
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  totalPages: number;
}

function PaginationButtons({
  currentPage,
  setCurrentPage,
  totalPages,
}: PaginationProps) {
  const goToPage = (page: number) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setCurrentPage(page);
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      setCurrentPage((prev) => prev + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <div className="flex items-center justify-center">
      <PaginationButton
        onClick={prevPage}
        className={`text-txt04 ${
          currentPage <= 1 ? 'pointer-events-none opacity-50' : ''
        }`}
        name="<<"
      />

      {Array.from({ length: totalPages }).map((_, index) => (
        <PaginationButton
          onClick={() => goToPage(index + 1)}
          className={`[:not(pointer-events-none_text-[#111])]: 
                                ${
                                  currentPage === index + 1
                                    ? ' pointer-events-none text-[#111]'
                                    : 'text-txt04'
                                }`}
          key={(index + 1).toString()}
          name={(index + 1).toString()}
        />
      ))}
      <PaginationButton
        onClick={nextPage}
        className={`text-txt04 ${
          currentPage >= totalPages ? 'pointer-events-none opacity-50' : ''
        }`}
        name=">>"
      />
    </div>
  );
}

export default PaginationButtons;
