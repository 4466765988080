import classNames from 'classnames';

type LabelDataType = {
  labelName: string;
  className: string | null;
  classColor?: string;
  classBorderColor?: string;
  labelFor: string;
};
function CategoryLabel(params: LabelDataType) {
  const {
    labelName,
    className,
    classColor = 'text-secondary_blue',
    classBorderColor = 'border-secondary_blue',
    labelFor,
  } = params;
  const defaultClass: string =
    'table-cell h-6 rounded-md border-[1px] border-solid bg-white px-2 py-0 text-[11px] font-semibold leading-6 last:mr-0 sm:text-[12px]';

  return (
    <label
      htmlFor={labelFor}
      key={labelName}
      className={classNames(
        defaultClass,
        classColor,
        classBorderColor,
        className,
      )}
    >
      {labelName}
    </label>
  );
}
export default CategoryLabel;
