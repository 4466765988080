import React from 'react';
import classNames from 'classnames';

type BtnProps = {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  name: string;
};

function PaginationButton(params: BtnProps) {
  const { onClick, className, name } = params;

  const defaultClass: string =
    'flex h-[45px] w-[45px] flex-wrap items-center justify-center bg-txt01 text-xl font-bold transition-all duration-150 ease-linear hover:opacity-50';

  return (
    <button
      type="button"
      key={name}
      onClick={onClick}
      className={classNames(defaultClass, className)}
    >
      {name}
    </button>
  );
}

export default PaginationButton;
