'use client';

import {useEffect, useState} from 'react';
import classNames from "classnames";

const TopSeasonImage = () => {

    const [seasonsImage, setSeasonsImage] = useState<string>('bg-main-image-default');
    // 季節ごとの画像を選定
    const getSeasonImage = () => {
        const now = new Date();
        const month = now.getMonth() + 1;
        const date = now.getDate();

        switch (month) {
            case 3:
            case 4:
            case 5:
                return 'bg-main-image-spring';

            case 6:
            case 7:
            case 8:
                return 'bg-main-image-summer';

            case 9:
            case 10:
            case 11:
                return 'bg-main-image-autumn';

            case 12:
                return 'bg-main-image-winter';

            case 2:
                return 'bg-main-image-winter1';

            case 1:
                if (date < 8) {
                    return 'bg-main-image-new-year';
                } else {
                    return 'bg-main-image-winter1';
                }

            default:
                return 'bg-main-image-default';
        }
    };

    // 当月画像を取得
    useEffect(() => {
        setSeasonsImage(getSeasonImage);
    }, []);

    const defaultClass: string = 'my-0 h-[500px] bg-cover bg-[center_center] p-0';

    return (
        <>
            <div className={classNames( seasonsImage ,defaultClass)} />
        </>
    );
}
export default TopSeasonImage;