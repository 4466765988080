// お知らせ
export interface Notice {
  id: number;
  category: number;
  title: string;
  body: string;
  release_at: string;
  deleted_at: string | null;
  created_at: string;
  updated_at: string;
}

export const initialNotice: Notice = {
  id: 0,
  category: 0,
  title: '',
  body: '',
  release_at: '',
  deleted_at: null,
  created_at: '',
  updated_at: '',
};

export type Props = { params: { id: string } };

export type ImageData = {
  src: string;
  width?: number;
  height?: number;
  alt: string;
  className?: string;
};

// カテゴリナンバーに該当する文字列を返す
export const categoryNumberToString = (num: number) => {
  if (num < 1 || num > 3) {
    return '-';
  }

  const categoryList = [
    { category: 'お知らせ', val: 1 },
    { category: 'メンテナンス', val: 2 },
    { category: 'プレスリリース', val: 3 },
  ];

  const category = categoryList.filter((newsList) => num === newsList.val);
  return category[0].category;
};

// 日付を yyyy.mm.dd の形式で返す
export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}.${month}.${day}`;
};
