'use client';

import React, { useState, useEffect } from 'react';

import Link from 'next/link';
import axios from 'axios';
import { CONSTANTS } from '@/app/consts/constants';
import PaginationButtons from '@/app/components/pagination';

import * as Utils from 'src/app/utils/utils';
import ReadMore from '@/app/components/readMore';
import CategoryLabel from '@/app/components/categoryLabel';

function News(props: { page: string }) {
  const [notices, setNotices] = useState<Utils.Notice[]>([]);

  const { page } = props;
  const flag = page === 'HOME';

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const ITEMS_PER_PAGE = 20;
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentData = notices;

  function ReadMoreLink() {
    return (
      <div className="w-full text-right">
        <ReadMore href="/news" />
      </div>
    );
  }

  useEffect(() => {
    const baseurl = CONSTANTS.BASE_URL;
    const url = `${baseurl}/news_list?_limit=${flag ? 5 : ITEMS_PER_PAGE}&_offset=${startIndex}`;

    const newsList = async () => {
      const response = await axios.get(url).catch((e) => {
        console.log(e);
      });
      if (response?.data.data.list && response?.data.data.rowCount) {
        setNotices(response.data.data.list);
        setTotalPages(Math.ceil(response.data.data.rowCount / ITEMS_PER_PAGE));
      }
    };
    newsList();
  }, [currentPage, flag, startIndex]);

  return (
    <div>
      <dl className="mb-8 block w-full bg-[#fafafa] px-8 pb-8 pt-4">
        {currentData.map((notice: Utils.Notice) => (
          <Link
            className="w-full md:flex md:flex-wrap
            [&>*]:border-solid [&>*]:border-gray-200 [&>*]:pl-6 [&>*]:md:px-2 [&>*]:md:py-6
            [&>dd]:pb-6"
            key={notice.id}
            href={{
              pathname: `/news/detail/`,
              query: { id: notice.id, from: page },
            }}
            prefetch={false}
          >
            <dt
              className="basis-1/5 pt-6 text-xs
          font-semibold sm:text-sm md:border-b"
            >
              {Utils.formatDate(notice.release_at)}
            </dt>
            <dd className="md:basis-1/5 md:border-b">
              <CategoryLabel labelName={Utils.categoryNumberToString(notice.category)} className="" labelFor="" />
            </dd>
            <dd className="border-b text-xs sm:text-sm md:basis-3/5">{notice.title}</dd>
          </Link>
        ))}
      </dl>
      {flag ? (
        <ReadMoreLink />
      ) : (
        <PaginationButtons currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
      )}
    </div>
  );
}

export default News;
